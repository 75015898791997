/** @jsx jsx */
import {jsx} from 'theme-ui'

type Props = {
  title: string
  subtitle: string
}

const CausesHead: React.FC<Props> = ({title, subtitle}) => {
  return (
    <section
      id="projects__head"
      sx={{
        backgroundColor: ['lightGrey', , 'transparent'],
      }}
    >
      <div
        sx={{
          width: ['100%', , 1440 - 208 * 2],
          mx: 'auto',
          px: [28, , 0],
          maxWidth: '90%',
        }}
      >
        <h1
          sx={{
            fontSize: [24, , 32],
            fontWeight: 400,
            pt: 42,
            mb: 0,
            mt: 0,
          }}
        >
          {title}
        </h1>
        <p
          sx={{
            fontSize: [16, , 18],
            mt: [9, , 17],
            pb: 26,
            mb: 0,
          }}
        >
          {subtitle}
        </p>
      </div>
    </section>
  )
}

export default CausesHead

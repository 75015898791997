/** @jsx jsx */
import {graphql} from 'gatsby'
import React from 'react'
import {jsx} from 'theme-ui'
import {CauseProjectsFragment} from '../graphqlTypes'
import ProjectCard from './ProjectCard'

type Props = {
  data: CauseProjectsFragment
}

const CauseProjects: React.FC<Props> = ({data}) => {
  const projects = data.allMdx.nodes
  return (
    <section
      id="causes__projects"
      sx={{
        mt: [0, , 40],
        width: ['100%', , 1128],
        maxWidth: '100%',
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: ['column', , 'row'],
        flexWrap: 'wrap',
        justifyContent: [, , 'space-around', 'space-between'],
      }}
    >
      {projects.map(({frontmatter, parent}) => {
        let slug = parent?.name
        if (slug.endsWith('.fr')) {
          slug = 'fr/' + slug.replace('.fr', '')
        }
        const {shortDescription, shortTitle, variableName} = frontmatter
        // @ts-ignore
        const imgObj = data[variableName]
        return (
          <ProjectCard
            key={slug}
            sx={{
              mt: [0, , 40],
              flexBasis: [, , '34%', 'unset'],
            }}
            title={shortTitle}
            description={shortDescription}
            slug={slug}
            imgObj={imgObj}
          />
        )
      })}
    </section>
  )
}

export default React.memo(CauseProjects)

export const query = graphql`
  fragment CauseProjects on Query {
    allMdx(
      filter: {frontmatter: {type: {eq: "cause"}, language: {eq: $langKey}}}
      sort: {order: ASC, fields: frontmatter___causesOrder}
    ) {
      nodes {
        frontmatter {
          shortDescription
          shortTitle
          variableName
        }
        parent {
          ... on File {
            id
            name
          }
        }
      }
    }
    atlasRelief: file(relativePath: {eq: "project-atlas-relief-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ansar: file(relativePath: {eq: "project-ansar-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zakat: file(relativePath: {eq: "project-zakat-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ramadan: file(relativePath: {eq: "project-ramadan-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    water4All: file(relativePath: {eq: "project-w4all-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    banyUmmaty: file(relativePath: {eq: "project-bany-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    feedingHope: file(relativePath: {eq: "project-feeding-hope-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/** @jsx jsx */
import Button from 'components/Button'
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import {graphql} from 'gatsby'
import {CausesQuery} from '../graphqlTypes'
import DonateNowButton from './DonateNowButton'
import {useTranslation} from 'react-i18next'

type Props = {
  className?: string
  data: CausesQuery
  title: string
  subtitle: string
  pagePath: string
  preTitle: string
  purpose?: string
}

const CausesHero: React.FC<Props> = ({
  className,
  data,
  title,
  subtitle,
  pagePath,
  preTitle,
  purpose,
}) => {
  const {heroImage} = data
  const {t} = useTranslation()
  return (
    <BackgroundImage
      className={className}
      id="home__hero"
      Tag="div"
      style={{
        backgroundSize: '',
        backgroundPosition: '',
      }}
      sx={{
        py: [15, , 37],
        px: [20, , 40],
        width: '100%',
        height: [650, 850, 510],
        display: 'flex',

        '&::before, &::after': {
          backgroundPositionX: 'left',
          backgroundPositionY: ['bottom', , 'center'],
          backgroundSize: ['180%', , 'unset'],
        },
      }}
      fluid={heroImage.childImageSharp.fluid}
      backgroundColor="#F4F7F8"
    >
      <div
        sx={{
          flex: 1,
          width: ['100%', , 1440 - 354 * 2],
          maxWidth: '100%',
          mx: 'auto',
          display: 'flex',
          flexDirection: [, 'column', 'row-reverse'],
          alignItems: ['flex-start', 'center'],
        }}
      >
        <div
          id="causes_hero__card"
          sx={{
            width: ['100%', '60%', 'unset', 432],
            backgroundColor: ['#fff', , 'transparent'],
            px: [15, , 0],
            py: [20, , 0],
            borderRadius: 14,
          }}
        >
          <span
            sx={{
              textTransform: 'uppercase',
              fontSize: 12,
              fontFamily: 'Lato Medium',
              color: 'primary',
            }}
          >
            {preTitle}
          </span>
          <h2
            sx={{
              fontFamily: 'Lato Medium',
              fontWeight: 400,
              fontSize: [24, , 32],
              mt: 14,
              mb: 0,
            }}
          >
            {title}
          </h2>
          <p
            sx={{
              mt: 11,
              fontSize: 14,
              color: 'soft',
              mb: 0,
              maxWidth: 292,
              lineHeight: '22px',
            }}
          >
            {subtitle}
          </p>
          <div
            sx={{
              mt: [22, , 35],
              display: 'flex',
              flexDirection: ['column', , 'row'],
            }}
          >
            <DonateNowButton
              to={purpose}
              sx={{
                px: 34,
              }}
            />

            <Button
              to={pagePath}
              inverse
              sx={{
                mt: [13, , 0],
                ml: [0, , 28],
                px: 22,
                display: 'flex',
                justifyContent: 'center',
                borderColor: ['primary', , 'transparent'],
                borderWidth: 1,
                borderStyle: 'solid',
              }}
            >
              {t('Learn More')}
            </Button>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default CausesHero

export const query = graphql`
  fragment CausesHero on Query {
    heroImage: file(relativePath: {eq: "project-bany-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/** @jsx jsx */
import {jsx} from 'theme-ui'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import {graphql, PageProps} from 'gatsby'
import {CausesQuery} from '../graphqlTypes'
import CausesHero from 'components/CausesHero'
import CausesHead from 'components/CausesHead'
import CausesProjects from 'components/CausesProjects'
import CTA from 'components/CTA'
import {useTranslation} from 'react-i18next'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'
import {PageContext} from 'gatsbyTypes'

interface Props extends PageProps<CausesQuery, PageContext> {}

const CausesPage: React.FC<Props> = ({data, pageContext}) => {
  useAutoSelectLanguage(pageContext)
  const {t, i18n} = useTranslation()
  const pagePath = i18n.language === 'en' ? '/ansar/' : '/fr/ansar/'

  return (
    <Layout
      sx={{
        pb: [0, 0, 121],
      }}
      navMobileColor="#F4F7F8"
    >
      <SEO
        title={t('Have a positive impact')}
        description={t('Commit to the cause that is dear to your heart')}
      />
      <CausesHead
        title={t('Have a positive impact')}
        subtitle={t('Commit to the cause that is dear to your heart')}
      />
      <CausesHero
        data={data}
        pagePath={pagePath}
        preTitle={t('FEATURED')}
        title={t('causesHeroTitle')}
        subtitle={t('causesHeroSubtitle')}
        purpose="ansar"
      />
      <CausesProjects data={data} />
      {/* TODO: Why the CTA and projects don't have same width ?? */}
      <CTA
        sx={{
          mt: '78px !important',
        }}
      />
    </Layout>
  )
}

export default CausesPage

export const pageQuery = graphql`
  query Causes($langKey: String!) {
    ...CausesHero
    ...CauseProjects
  }
`
